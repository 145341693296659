import { Page } from 'services/analytics/analyticsClient';
import { matchPath, useLocation } from 'react-router';
import { objectFlip } from 'services/utils';

export const Pages = {
  home: { name: 'Home', pathname: '/' },
  notFound: { name: 'NotFound', pathname: '/not-found' },
  forbidden: { name: 'Forbidden', pathname: '/forbidden' },
  login: { name: 'Login', pathname: '/login' },
  loginCheck: { name: 'Login check', pathname: '/login/check' },
  academyHome: { name: 'Academy', pathname: '/:academy_slug' },
};

export const AcademyPages = {
  focus: { name: 'Focus', pathname: 'focus/:focus_uuid' },
  completedFocus: { name: 'Focus (completed)', pathname: 'focus/:focus_uuid/completed' },
  course: { name: 'Course', pathname: ':course_slug' },
  defaultLibrary: { name: 'DefaultLibrary', pathname: 'default-library/:default_library_uuid' },
  step: { name: 'Step', pathname: 'focus/:focus_uuid/step/:step_uuid' },
  piece: { name: 'Piece', pathname: 'default-library/:default_library_uuid/piece/:piece_uuid' },
  skill: { name: 'Skill', pathname: ':course_slug/:skill_slug' },
  embedSkillTraining: { name: 'EmbedSkillTraining', pathname: ':course_slug/:skill_slug/embed' },
  designer: { name: 'DesignerRouter', pathname: 'designer' },
};

export const baseDesignerPath = `${Pages.academyHome.pathname}/${AcademyPages.designer.pathname}/`;

export const DesignerPages = {
  focus: { name: 'Focus', pathname: 'focus/:focus_uuid' },
  completionOfFocus: { name: 'Focus', pathname: 'focus/:focus_uuid/completed' },
  newCourse: { name: 'NewCourse', pathname: 'create-course' },
  course: { name: 'Course', pathname: ':course_slug' },
  defaultLibrary: { name: 'DefaultLibrary', pathname: 'default-library/:default_library_uuid' },
  step: { name: 'Step', pathname: 'focus/:focus_uuid/step/:step_uuid' },
  piece: { name: 'Piece', pathname: 'default-library/:default_library_uuid/piece/:piece_uuid' },
  newSkill: { name: 'NewSkill', pathname: ':course_slug/create-skill' },
  skill: { name: 'Skill', pathname: ':course_slug/:skill_slug' },
};

export function getPageFromPath(path: string): Page | null {
  for (const [, page] of Object.entries(Pages)) {
    if (matchPath(path, { path: page.pathname, exact: true }) !== null) {
      return { pathname: path, name: page.name };
    }
  }
  for (const [, page] of Object.entries(AcademyPages)) {
    const pagePath: string = Pages.academyHome.pathname + '/' + page.pathname;
    if (matchPath(path, { path: pagePath, exact: true }) !== null) {
      return { pathname: path, name: page.name };
    }
  }
  return { pathname: path, name: 'Unknown' };
}

export const useIsOnDesignerPage = (): { isOnDesignerPage: boolean } => {
  const { pathname } = useLocation();
  if (matchPath(pathname, { path: baseDesignerPath, exact: true }) !== null) {
    return { isOnDesignerPage: true };
  }
  for (const [, page] of Object.entries(DesignerPages)) {
    const pagePath: string = baseDesignerPath + page.pathname;
    if (matchPath(pathname, { path: pagePath, exact: true }) !== null) {
      return { isOnDesignerPage: true };
    }
  }
  return { isOnDesignerPage: false };
};

export const academyDesignerPathMapping = {
  [Pages.academyHome.pathname]: `${Pages.academyHome.pathname}/${AcademyPages.designer.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.focus.pathname}`]: `${baseDesignerPath}${DesignerPages.focus.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.completedFocus.pathname}`]: `${baseDesignerPath}${DesignerPages.completionOfFocus.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.course.pathname}`]: `${baseDesignerPath}${DesignerPages.course.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.step.pathname}`]: `${baseDesignerPath}${DesignerPages.step.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.skill.pathname}`]: `${baseDesignerPath}${DesignerPages.skill.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.defaultLibrary.pathname}`]: `${baseDesignerPath}${DesignerPages.defaultLibrary.pathname}`,
  [`${Pages.academyHome.pathname}/${AcademyPages.piece.pathname}`]: `${baseDesignerPath}${DesignerPages.piece.pathname}`,
};

export const designerAcademyPathMapping = objectFlip(academyDesignerPathMapping);
