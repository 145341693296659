import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetDefaultLibraryByUuid } from 'redux/DefaultLibrary/hooks';
import { useDialog, useHideDialog } from 'redux/Dialog/hooks';
import {
  KeyPointsDialogContent,
  KeyPointsDialogContainer,
  KeyPointsDialogTitle,
  KeyPointsDialogTitleContainer,
  KeyPointsDialogButtonClose,
  SkillStandard,
  SkillStandardMissing,
  Separator,
} from './DefaultLibraryKeyPointsDialog.style';
import { CloseIcon } from 'components/UI/SvgIcon/CloseIcon';
import Dialog from '../Dialog/Dialog';
import { Container, Row } from 'components/UI/Layout';

const DefaultLibraryKeyPointsDialog = (): JSX.Element => {
  const { handleOnClose } = useHideDialog();
  const intl = useIntl();
  const { dialog } = useDialog();

  const { default_library_uuid } = useMemo(() => {
    return {
      default_library_uuid: dialog.current_default_library_uuid,
    };
  }, [dialog]);

  const defaultLibrary = useGetDefaultLibraryByUuid(default_library_uuid || '');

  const keyPoints = useMemo(() => {
    return defaultLibrary?.key_points;
  }, [defaultLibrary]);

  return (
    <Dialog closeDialog={handleOnClose} hasBackgroundOverlay={true} alignY="flex-end">
      <Container>
        <Row>
          <KeyPointsDialogContainer>
            <KeyPointsDialogContent>
              <KeyPointsDialogTitleContainer>
                <KeyPointsDialogTitle>
                  {intl.formatMessage({ id: 'dialog.key-points.title' })}
                </KeyPointsDialogTitle>
                <KeyPointsDialogButtonClose
                  onClick={handleOnClose}
                  ariaLabel="close dialog button"
                  icon={<CloseIcon />}
                  buttonTheme="neutral"
                />
              </KeyPointsDialogTitleContainer>
              <Separator />
              {keyPoints?.map((keyPoint) => (
                <SkillStandard key={keyPoint.uuid}>{'✅ ' + keyPoint.description}</SkillStandard>
              ))}
              {!keyPoints && (
                <SkillStandardMissing>
                  {intl.formatMessage({ id: 'dialog.key-points.missing' })}
                </SkillStandardMissing>
              )}
            </KeyPointsDialogContent>
          </KeyPointsDialogContainer>
        </Row>
      </Container>
    </Dialog>
  );
};

export default DefaultLibraryKeyPointsDialog;
