import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDefaultLibrary,
  fetchDefaultLibraryByUuid,
  deleteDefaultLibrary,
  updateDefaultLibrary,
} from 'services/networking/default_library';
import { addDefaultLibraries, updateDefaultLibraries, removeDefaultLibrary } from './slice';
import { DefaultLibrary } from './types';
import { getDefaultLibraryByUuid } from './selectors';
import { useNavigateToPageError } from 'services/routes';

export const useGetDefaultLibraryByUuid = (
  default_library_uuid: string,
): DefaultLibrary | undefined => {
  const dispatch = useDispatch();
  const defaultLibrary = useSelector(getDefaultLibraryByUuid(default_library_uuid));
  const { navigateToPageError } = useNavigateToPageError();

  useEffect(() => {
    const fetchDefaultLibrary = async () => {
      const { defaultLibrary: newDefaultLibrary, error } =
        await fetchDefaultLibraryByUuid(default_library_uuid);
      if (error !== undefined) {
        navigateToPageError(error);
      }
      if (newDefaultLibrary !== null) {
        dispatch(addDefaultLibraries([newDefaultLibrary]));
      }
    };
    if (defaultLibrary === null || defaultLibrary === undefined) {
      fetchDefaultLibrary();
    }
  }, [dispatch, defaultLibrary, default_library_uuid, navigateToPageError]);
  return defaultLibrary;
};

export const useCreateDefaultLibrary = () => {
  const dispatch = useDispatch();
  return {
    createDefaultLibrary: useCallback(
      async (name: string, academy_slug: string, course_slug: string, skill_slug: string) => {
        const promise = new Promise<DefaultLibrary | void>((resolve, reject) => {
          createDefaultLibrary({ name: name }, academy_slug, course_slug, skill_slug).then(
            (createdDefaultLibrary) => {
              if (createdDefaultLibrary !== null) {
                dispatch(addDefaultLibraries([createdDefaultLibrary]));
                resolve(createdDefaultLibrary);
              } else {
                reject();
              }
            },
          );
        });
        return promise;
      },
      [dispatch],
    ),
  };
};

export const useDeleteDefaultLibrary = () => {
  const dispatch = useDispatch();
  return {
    deleteDefaultLibrary: useCallback(
      async (defaultLibraryUuid: string) => {
        const promise = new Promise<void>((resolve, reject) => {
          deleteDefaultLibrary(defaultLibraryUuid).then((response) => {
            if (response === 'OK') {
              dispatch(removeDefaultLibrary(defaultLibraryUuid));
              resolve();
            } else {
              reject();
            }
          });
        });
        return promise;
      },
      [dispatch],
    ),
  };
};

export const useUpdateDefaultLibrary = (): {
  updateDefaultLibrary: (defaultLibraryToUpdate: FormData) => Promise<void>;
} => {
  const dispatch = useDispatch();
  return {
    updateDefaultLibrary: useCallback(
      async (defaultLibraryToUpdate: FormData) => {
        const promise = new Promise<void>((resolve, reject) => {
          updateDefaultLibrary(defaultLibraryToUpdate).then((updatedDefaultLibrary) => {
            if (updatedDefaultLibrary !== null) {
              dispatch(updateDefaultLibraries([updatedDefaultLibrary]));
              resolve();
            } else {
              reject();
            }
          });
        });
        return promise;
      },
      [dispatch],
    ),
  };
};
