import { DefaultLibrary, KeyPoint } from 'redux/DefaultLibrary/types';
import client from 'services/networking/client';
import { ErrorStatus, RequestResult } from './types';

interface DefaultLibraryResponse {
  count: number;
  next: string;
  previous: string;
  results: DefaultLibrary[];
}

export type DefaultLibraryToCreate = {
  name?: string;
};

export const fetchDefaultLibraryByUuid = async (
  default_library_uuid: string,
): Promise<{ defaultLibrary: DefaultLibrary | null; error?: ErrorStatus }> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}default_libraries/${default_library_uuid}/`;
    const defaultLibrary: DefaultLibrary = await client.get(api_endpoint);
    return { defaultLibrary };
  } catch (e: any) {
    if ([403, 404].includes(e.status)) {
      return { defaultLibrary: null, error: e.status };
    }
    return { defaultLibrary: null };
  }
};

export const createDefaultLibrary = async (
  defaultLibraryToCreate: DefaultLibraryToCreate,
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
): Promise<DefaultLibrary | null> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/${skill_slug}/default_libraries/`;
    const defaultLibrary: DefaultLibrary = await client.post(api_endpoint, defaultLibraryToCreate);

    return defaultLibrary;
  } catch (e) {
    return null;
  }
};

export async function fetchDefaultLibrariesBySkill(
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
): Promise<{ defaultLibraries: DefaultLibrary[] | null; error?: ErrorStatus }> {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/${skill_slug}/default_libraries/`;
    const response: DefaultLibraryResponse = await client.get(endpoint);
    return { defaultLibraries: response.results };
  } catch (e: any) {
    if ([403, 404].includes(e.status)) {
      return { defaultLibraries: null, error: e.status };
    }
    return { defaultLibraries: null };
  }
}

export const deleteDefaultLibrary = async (defaultLibraryUuid: string): Promise<'OK' | null> => {
  try {
    const api_endpoint = `${process.env.REACT_APP_API_URL_CODE}default_libraries/${defaultLibraryUuid}/`;
    await client.delete(api_endpoint);
    return 'OK';
  } catch (e) {
    return null;
  }
};

export type DefaultLibraryToUpdate = {
  uuid: string;
  name?: string;
  key_points?: KeyPoint[];
};

export const updateDefaultLibrary = async (
  updatedDefaultLibrary: FormData,
): Promise<DefaultLibrary | null> => {
  try {
    const api_endpoint = `${
      process.env.REACT_APP_API_URL_CODE
    }default_libraries/${updatedDefaultLibrary.get('uuid')}/`;
    const defaultLibrary: DefaultLibrary = await client.patch(api_endpoint, updatedDefaultLibrary);
    return defaultLibrary;
  } catch (e) {
    return null;
  }
};

export const orderDefaultLibraries = async (
  academy_slug: string,
  course_slug: string,
  skill_slug: string,
  default_libraries_uuids: string[],
): Promise<RequestResult> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL_CODE}academies/${academy_slug}/courses/${course_slug}/skills/${skill_slug}/default_libraries/order`;
    await client.post(endpoint, { order: default_libraries_uuids });
    return 'OK';
  } catch (e) {
    return 'ERROR';
  }
};
