import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultLibrary } from './types';

export type DefaultLibraryState = Readonly<{
  defaultLibraries: DefaultLibrary[];
}>;

const initialState: DefaultLibraryState = { defaultLibraries: [] };

const DefaultLibrarySlice = createSlice({
  name: 'DefaultLibrary',
  initialState,
  reducers: {
    addDefaultLibraries: (state, action: PayloadAction<DefaultLibrary[]>) => {
      action.payload.forEach((defaultLibrary: DefaultLibrary) => {
        state.defaultLibraries.push(defaultLibrary);
      });
    },
    updateDefaultLibraries: (state, action: PayloadAction<DefaultLibrary[]>) => {
      const updatedDefaultLibraries = state.defaultLibraries.map(
        (val) =>
          action.payload.find(
            (defaultLibrary: DefaultLibrary) => defaultLibrary.uuid === val.uuid,
          ) || val,
      );
      state.defaultLibraries = updatedDefaultLibraries;
    },
    removeDefaultLibrary: (state, action: PayloadAction<string>) => {
      state.defaultLibraries = state.defaultLibraries.filter(
        (defaultLibrary) => defaultLibrary.uuid !== action.payload,
      );
    },
    setDefaultLibraries: (state, action: PayloadAction<DefaultLibrary[]>) => {
      state.defaultLibraries = action.payload;
    },
  },
});

export const {
  addDefaultLibraries,
  updateDefaultLibraries,
  removeDefaultLibrary,
  setDefaultLibraries,
} = DefaultLibrarySlice.actions;
export default DefaultLibrarySlice.reducer;
