import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Piece } from './types';

export type PieceState = Readonly<{
  pieces: Piece[];
}>;

const initialState: PieceState = { pieces: [] };

const piecesSlice = createSlice({
  name: 'Pieces',
  initialState,
  reducers: {
    addPieces: (state, action: PayloadAction<Piece[]>) => {
      action.payload.forEach((piece: Piece) => {
        state.pieces.push(piece);
      });
    },
    updatePieces: (state, action: PayloadAction<Piece[]>) => {
      const updatedPieces = state.pieces.map(
        (val) => action.payload.find((piece: Piece) => piece.uuid === val.uuid) || val,
      );
      state.pieces = updatedPieces;
    },
    addPiece: (state, action) => {
      state.pieces.push(action.payload);
    },
    removePiece: (state, action: PayloadAction<string>) => {
      console.log('action.payload', action.payload);
      state.pieces = state.pieces.filter((piece) => piece.uuid !== action.payload);
    },
  },
});

export const { addPieces, updatePieces, addPiece, removePiece } = piecesSlice.actions;
export default piecesSlice.reducer;
