import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const STOP_TRAINING = 'STOP_TRAINING';
const KEY_POINTS = 'KEY_POINTS';
const DEFAULT_LIBRARY_KEY_POINTS = 'DEFAULT_LIBRARY_KEY_POINTS';
const ACCESS_DENIED = 'ACCESS_DENIED';

export const dialogTypes: {
  stopTraining: 'STOP_TRAINING';
  keyPoints: 'KEY_POINTS';
  defaultLibraryKeyPoints: 'DEFAULT_LIBRARY_KEY_POINTS';
  accessDenied: 'ACCESS_DENIED';
} = {
  stopTraining: STOP_TRAINING,
  keyPoints: KEY_POINTS,
  defaultLibraryKeyPoints: DEFAULT_LIBRARY_KEY_POINTS,
  accessDenied: ACCESS_DENIED,
};

export type DialogType =
  | typeof STOP_TRAINING
  | typeof KEY_POINTS
  | typeof DEFAULT_LIBRARY_KEY_POINTS
  | typeof ACCESS_DENIED;

type DialogPayload = {
  type: DialogType;
  confirmAction?: () => void;
  current_academy_slug?: null | string;
  current_course_slug?: null | string;
  current_skill_slug?: null | string;
  current_default_library_uuid?: null | string;
  emailUserDenied?: string;
};

export type CurrentDialog = {
  show: boolean;
  type: null | DialogType;
  confirmAction?: () => void;
  current_academy_slug?: null | string;
  current_course_slug?: null | string;
  current_skill_slug?: null | string;
  current_default_library_uuid?: null | string;
  emailUserDenied?: null | string;
};

export type DialogState = Readonly<CurrentDialog>;

const getInitialState = (): DialogState => ({
  show: false,
  type: null,
  confirmAction: undefined,
  current_academy_slug: null,
  current_course_slug: null,
  current_skill_slug: null,
  current_default_library_uuid: null,
  emailUserDenied: null,
});

const dialogSlice = createSlice({
  name: 'Dialog',
  initialState: getInitialState(),
  reducers: {
    showDialogAction: (state, action: PayloadAction<DialogPayload>) => {
      state.show = true;
      state.type = action.payload.type;
      state.confirmAction = action.payload.confirmAction;
      state.current_academy_slug = action.payload.current_academy_slug;
      state.current_course_slug = action.payload.current_course_slug;
      state.current_skill_slug = action.payload.current_skill_slug;
      state.current_default_library_uuid = action.payload.current_default_library_uuid;
      state.emailUserDenied = action.payload.emailUserDenied;
    },
    hideDialog: (state) => {
      state.show = false;
      state.type = null;
      state.confirmAction = undefined;
      state.current_academy_slug = null;
      state.current_course_slug = null;
      state.current_skill_slug = null;
      state.current_default_library_uuid = null;
      state.emailUserDenied = null;
    },
  },
});

export const { showDialogAction, hideDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
