import { RootState } from 'redux/types';
import { DefaultLibrary } from './types';

const filterDefaultLibrariesBySkill = (
  defaultLibraries: DefaultLibrary[],
  skill_uuid: string,
): DefaultLibrary[] => {
  return defaultLibraries.filter((defaultLibrary) => defaultLibrary.skill === skill_uuid);
};

export const getDefaultLibrariesBySkill =
  (skill_uuid: string) =>
  (state: RootState): DefaultLibrary[] => {
    const defaultLibraries = state.defaultLibrary.defaultLibraries;
    return filterDefaultLibrariesBySkill(defaultLibraries, skill_uuid);
  };

export const getDefaultLibraryByUuid =
  (default_library_uuid: string) =>
  (store: RootState): DefaultLibrary | undefined => {
    const defaultLibraries = store.defaultLibrary.defaultLibraries;
    return defaultLibraries.find(
      (defaultLibrary: DefaultLibrary) => defaultLibrary.uuid === default_library_uuid,
    );
  };
